//jQuery(document).ready(function(){
function buildMap(mapElement) {
	if ( $('.location__map').length > 0 ){
	
		var styledMap;
		
		if ( $('body').hasClass('theme-cadogan') ) {
			styledMap = new google.maps.StyledMapType(cadoganMap ,{name: "Styled Map"});	
		} else if ( $('body').hasClass('theme-chewton') ) {
			styledMap = new google.maps.StyledMapType(chewtonMap ,{name: "Styled Map"});	
		} else if ( $('body').hasClass('theme-cliveden') ) {
			styledMap = new google.maps.StyledMapType(clivedenMap ,{name: "Styled Map"});	
		} else if ( $('body').hasClass('theme-lygon') ) {
			styledMap = new google.maps.StyledMapType(lygonMap ,{name: "Styled Map"});	
		} else {
			styledMap = new google.maps.StyledMapType(mayfairMap ,{name: "Styled Map"});
		}
		
		if (typeof mapPoints !== 'undefined') {
			var mapCenter = new google.maps.LatLng(mapPoints.centerLat, mapPoints.centerLong);
		} else {
			var mapCenter = new google.maps.LatLng(latitude, longitude);
		}
		
		if (typeof mapPoints !== 'undefined') {
			var zoomLevel = 14;
			
			if ( (mapPoints.points.length > 1) && ($('body').hasClass('theme-cadogan')) ) {
				zoomLevel = 16; // zoom further in for Cadogan location when showing the hotel and Hans Bar
			}
			
			var map = new google.maps.Map(jQuery('#mapLocationSrc').get(0), {
				center: mapCenter,
				zoom: zoomLevel,
				scrollwheel: false,
				mapTypeControl: false,
				streetViewControl: false,
				fullscreenControl: false,
				mapTypeId: google.maps.MapTypeId.ROADMAP
			});
			
			// Loop through multiple markers
			for (i=0; i < mapPoints.points.length; i++) {
				marker = new google.maps.Marker({
					position: {lat: mapPoints.points[i].latitude, lng: mapPoints.points[i].longitude},
					map: map
				});
				
				marker.setIcon( mapPoints.points[i].markerIcon.url );
				
				
			}
		} else {
			var map = new google.maps.Map(jQuery('#mapLocationSrc').get(0),{
				center: mapCenter,
				zoom: 14,
				scrollwheel: false,
				mapTypeControl: false,
				streetViewControl: false,
				fullscreenControl: false,
				mapTypeId: google.maps.MapTypeId.ROADMAP
			}),
			marker = new google.maps.Marker({
				position: new google.maps.LatLng(latitude, longitude),
				icon: markerIcon,
				map: map
			}),
			info = new google.maps.InfoWindow({
			content: popupContent
			});
		}
		
		map.mapTypes.set('map_style', styledMap);
		map.setMapTypeId('map_style');
		
		var old_v='',
		ds=new google.maps.DirectionsService(),
		dr=new google.maps.DirectionsRenderer();
		dr.setMap(map);
		dr.setPanel(jQuery('#map-directions-data').get(0));  
		
	}
}
//});


var mayfairMap=[{featureType:"administrative",elementType:"labels.text.fill",stylers:[{color:"#444444"}]},{featureType:"landscape",elementType:"all",stylers:[{color:"#e9e8e5"}]},{featureType:"poi",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"road",elementType:"all",stylers:[{saturation:-100},{lightness:45}]},{featureType:"road.highway",elementType:"all",stylers:[{visibility:"simplified"}]},{featureType:"road.arterial",elementType:"labels.icon",stylers:[{visibility:"off"}]},{featureType:"transit",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"water",elementType:"all",stylers:[{color:"#8c98ac"},{visibility:"on"}]}];

var cadoganMap=[{featureType:"all",elementType:"geometry.fill",stylers:[{weight:"2.00"}]},{featureType:"all",elementType:"geometry.stroke",stylers:[{color:"#9c9c9c"}]},{featureType:"all",elementType:"labels.text",stylers:[{visibility:"on"}]},{featureType:"landscape",elementType:"all",stylers:[{color:"#f2f2f2"}]},{featureType:"landscape",elementType:"geometry.fill",stylers:[{color:"#ffffff"}]},{featureType:"landscape.man_made",elementType:"geometry.fill",stylers:[{color:"#ffffff"}]},{featureType:"poi",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"road",elementType:"all",stylers:[{saturation:-100},{lightness:45}]},{featureType:"road",elementType:"geometry.fill",stylers:[{color:"#eeeeee"}]},{featureType:"road",elementType:"labels.text.fill",stylers:[{color:"#7b7b7b"}]},{featureType:"road",elementType:"labels.text.stroke",stylers:[{color:"#ffffff"}]},{featureType:"road.highway",elementType:"all",stylers:[{visibility:"simplified"}]},{featureType:"road.highway.controlled_access",elementType:"geometry.fill",stylers:[{visibility:"simplified"},{color:"#4d7388"}]},{featureType:"road.arterial",elementType:"labels.icon",stylers:[{visibility:"off"}]},{featureType:"transit",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"water",elementType:"all",stylers:[{color:"#46bcec"},{visibility:"on"}]},{featureType:"water",elementType:"geometry.fill",stylers:[{color:"#c8d7d4"}]},{featureType:"water",elementType:"labels.text.fill",stylers:[{color:"#070707"}]},{featureType:"water",elementType:"labels.text.stroke",stylers:[{color:"#ffffff"}]}];

var chewtonMap=[{featureType:"all",elementType:"geometry.fill",stylers:[{weight:"2.00"}]},{featureType:"all",elementType:"geometry.stroke",stylers:[{color:"#9c9c9c"}]},{featureType:"all",elementType:"labels.text",stylers:[{visibility:"on"}]},{featureType:"landscape",elementType:"all",stylers:[{color:"#f2f2f2"}]},{featureType:"landscape",elementType:"geometry.fill",stylers:[{color:"#ffffff"}]},{featureType:"landscape.man_made",elementType:"geometry.fill",stylers:[{color:"#ffffff"}]},{featureType:"poi",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"road",elementType:"all",stylers:[{saturation:-100},{lightness:45}]},{featureType:"road",elementType:"geometry.fill",stylers:[{color:"#eeeeee"}]},{featureType:"road",elementType:"labels.text.fill",stylers:[{color:"#7b7b7b"}]},{featureType:"road",elementType:"labels.text.stroke",stylers:[{color:"#ffffff"}]},{featureType:"road.highway",elementType:"all",stylers:[{visibility:"simplified"}]},{featureType:"road.highway.controlled_access",elementType:"geometry.fill",stylers:[{visibility:"simplified"},{color:"#4d7388"}]},{featureType:"road.arterial",elementType:"labels.icon",stylers:[{visibility:"off"}]},{featureType:"transit",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"water",elementType:"all",stylers:[{color:"#46bcec"},{visibility:"on"}]},{featureType:"water",elementType:"geometry.fill",stylers:[{color:"#c8d7d4"}]},{featureType:"water",elementType:"labels.text.fill",stylers:[{color:"#070707"}]},{featureType:"water",elementType:"labels.text.stroke",stylers:[{color:"#ffffff"}]}];

var clivedenMap=[{featureType:"all",elementType:"geometry.fill",stylers:[{weight:"2.00"}]},{featureType:"all",elementType:"geometry.stroke",stylers:[{color:"#9c9c9c"}]},{featureType:"all",elementType:"labels.text",stylers:[{visibility:"on"}]},{featureType:"landscape",elementType:"all",stylers:[{color:"#f2f2f2"}]},{featureType:"landscape",elementType:"geometry.fill",stylers:[{color:"#ffffff"}]},{featureType:"landscape.man_made",elementType:"geometry.fill",stylers:[{color:"#ffffff"}]},{featureType:"poi",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"road",elementType:"all",stylers:[{saturation:-100},{lightness:45}]},{featureType:"road",elementType:"geometry.fill",stylers:[{color:"#eeeeee"}]},{featureType:"road",elementType:"labels.text.fill",stylers:[{color:"#7b7b7b"}]},{featureType:"road",elementType:"labels.text.stroke",stylers:[{color:"#ffffff"}]},{featureType:"road.highway",elementType:"all",stylers:[{visibility:"simplified"}]},{featureType:"road.highway.controlled_access",elementType:"geometry.fill",stylers:[{visibility:"simplified"},{color:"#4d7388"}]},{featureType:"road.arterial",elementType:"labels.icon",stylers:[{visibility:"off"}]},{featureType:"transit",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"water",elementType:"all",stylers:[{color:"#46bcec"},{visibility:"on"}]},{featureType:"water",elementType:"geometry.fill",stylers:[{color:"#c8d7d4"}]},{featureType:"water",elementType:"labels.text.fill",stylers:[{color:"#070707"}]},{featureType:"water",elementType:"labels.text.stroke",stylers:[{color:"#ffffff"}]}];

var lygonMap=[{featureType:"all",elementType:"geometry.fill",stylers:[{weight:"2.00"}]},{featureType:"all",elementType:"geometry.stroke",stylers:[{color:"#9c9c9c"}]},{featureType:"all",elementType:"labels.text",stylers:[{visibility:"on"}]},{featureType:"landscape",elementType:"all",stylers:[{color:"#f2f2f2"}]},{featureType:"landscape",elementType:"geometry.fill",stylers:[{color:"#ffffff"}]},{featureType:"landscape.man_made",elementType:"geometry.fill",stylers:[{color:"#ffffff"}]},{featureType:"poi",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"road",elementType:"all",stylers:[{saturation:-100},{lightness:45}]},{featureType:"road",elementType:"geometry.fill",stylers:[{color:"#eeeeee"}]},{featureType:"road",elementType:"labels.text.fill",stylers:[{color:"#7b7b7b"}]},{featureType:"road",elementType:"labels.text.stroke",stylers:[{color:"#ffffff"}]},{featureType:"road.highway",elementType:"all",stylers:[{visibility:"simplified"}]},{featureType:"road.highway.controlled_access",elementType:"geometry.fill",stylers:[{visibility:"simplified"},{color:"#4d7388"}]},{featureType:"road.arterial",elementType:"labels.icon",stylers:[{visibility:"off"}]},{featureType:"transit",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"water",elementType:"all",stylers:[{color:"#46bcec"},{visibility:"on"}]},{featureType:"water",elementType:"geometry.fill",stylers:[{color:"#c8d7d4"}]},{featureType:"water",elementType:"labels.text.fill",stylers:[{color:"#070707"}]},{featureType:"water",elementType:"labels.text.stroke",stylers:[{color:"#ffffff"}]}];
