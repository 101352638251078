$(document).foundation();
$(document).ready(function() {

    inlineSVGs([
        '.header__navigation-secondary img',
        '.social__titles img',
        '.header__mobile-burger img',
        '.footer__mayfair-info-logo img',
        '.footer__iconic-logo img',
        '.header__gift a span img'
    ]);

    initAnimateInView();
    initVerticalCenter();
    initHeader();
    // initContactForms();
    initWindowResize();
    initSliders();
    //initCustomSelect();
    //initBooking();
    initAccordions();
    // addMarginWhenNoSlider();
    initGallery();
    initPopups();
    initPress();
    initGridPanels();
    initSplitIntroOverlay();
    initNewsletterOffCanvas();
    showNewsletterForm();
    initCookerySchool();
    initTreatments();
    initLazyLoading();
    initStarReviews();
    initNewsletterPopUp();
    initLazyCurator();

    // video related function are handled by videoModule script
});





$( ".split-intro__image-video-link" ).click(function() {
  $('.inline-video').show();
  $(this).hide();
  $('.split-intro__image').css('background-image','none');
});

function initContactForms() {
    $('.validate-form').validate();
}

function addMarginWhenNoSlider() {
    if ( $('.slider').length < 1 ) {
        if ( $(window).outerWidth() < 640 ) {
            $('main').children().first().css("margin-top", '160px');
        } else {
            $('main').children().first().css("margin-top", '210px');
        }
    }
}

function initWindowResize() {
    $(window).on('resize', function() {
        setHeaderTransparency();
    });
}


function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function initAccordions() {
    $('.accordion__wrapper').accordiom({
        showFirstItem: false
    });
}

function initSliders() {

  if ( $('.slider iframe').length > 0 ) {
	   $('body').addClass('has-video');
   }

    if ( $('body.home').length > -1 ) {
        $('.slider').removeClass('slider--full-height');
        $('window').resize();
    }

    if ($('.slider__slide iframe-wrap iframe').length > 0) {
        // video logic handled elsewhere
        return 
    } else {
        $('.slider__inner').reformSlider({
            autoPlay: true,
            speed: 7000,
            animationSpeed: 1500,
            arrows: false,
            pagination: false
        });
    }

   

    setTimeout(function () {
        $('.slider__overlay-slide').fadeOut(2000);
    }, 3000);

    if ( $('.slider__caption').length > 0 ) {
        $('.slider__caption:not(:first)').css('display', 'none');
    }

    $('.featured-offer-slider__slider').slick({
        slidesToShow: 1,
        slidesToscroll: 1,
        arrows: true,
        pagination: false
    })

    $('.detail-slider__main-slider').slick({
        slidesToShow: 1,
        slidesToscroll: 1,
        arrows: false,
    })

    if ( $('.detail-slider__thumb-slider .detail-slider__thumb-slider-slide').length > 1 ) {
        $('.detail-slider__thumb-slider').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            asNavFor: '.detail-slider__main-slider',
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                        centerMode: true,
                        centerPadding: '30px'
                    }
                }
            ]
        })
    } else {
        $('.detail-slider__thumb-slider').remove();  // Hide the thumbnails if there's only 1 image
    }



    $('.sibling-slider__slider').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    })

    $('.sibling-slider__slider-slide').on('mouseenter', function() {
        $(this).find('.sibling-slider__item-hidden').css('opacity', '1');
    })

    $('.sibling-slider__slider-slide').on('mouseleave', function() {
        $(this).find('.sibling-slider__item-hidden').css('opacity', '0');
    })

    $('.grid-carousel__slider').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    })

    $('.reviews__slider').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        infinite: false,
        
        
        customPaging : function(slider, i) {
        var thumb = $(slider.$slides[i]).data();
        return '<a>'+(i+1) +'</a>';
            },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: true,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    dots: false,
                }
            }
        ]
    })
}

function initVerticalCenter() {
    $('.vertical-center').each(function () {
        $(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
    });
}

function initAnimateInView() {
	$(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
	animateElementsInView();
}

function animateElementsInView() {
  $('[data-animate-in-view]').each(function () {
    if ( $(this).hasClass('in-view') ) {
      return;
    }

    if ( isElementInViewport($(this)) ) {
      var elementToAnimate = this;

      $(elementToAnimate).addClass('in-view'); // log that this element has been seen


      setTimeout(function () {
        $(elementToAnimate).addClass( $(elementToAnimate).data('animate-in-view') ); // apply the animation class
      }, 300);


      if ( typeof $(elementToAnimate).data('map-load-on-view') != 'undefined' ) { // only load this map once it's in view
        buildMap(elementToAnimate);
      }
    }
  });
}

// https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
function isElementInViewport (el) {
    //special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    var t = rect.y;
    var b = window.innerHeight - rect.y - rect.height;
    var h = window.innerHeight;

    if (
	    ( (t >= 0) && (t <= h) ) ||
	    ( (b >= 0) && (b <= h) )
    ) {
	    return true;
    } else {
	    return false;
    }
}

// https://stackoverflow.com/questions/24933430/img-src-svg-changing-the-fill-color
function inlineSVGs(inputValue) {
    var selectorString = '';
    if (typeof inputValue === 'object') {
        selectorString = inputValue.join(',');
    } else {
        selectorString = inputValue;
    }

    $(selectorString).each(function() {
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        if (imgURL.indexOf('.svg') > -1) {
            $.get(
                imgURL,
                function(data) {
                    // Get the SVG tag, ignore the rest
                    var $svg = $(data).find('svg');

                    // Add replaced image's ID to the new SVG
                    if (typeof imgID !== 'undefined') {
                        $svg = $svg.attr('id', imgID);
                    }
                    // Add replaced image's classes to the new SVG
                    if (typeof imgClass !== 'undefined') {
                        $svg = $svg.attr('class', imgClass + ' replaced-svg');
                    }

                    // Remove any invalid XML tags as per http://validator.w3.org
                    $svg = $svg.removeAttr('xmlns:a');

                    // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
                    if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                        $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
                    }

                    // Replace image with new SVG
                    $img.replaceWith($svg);
                },
                'xml'
                );
        }
    });
}

function initHeader() {

  // Quick logo change before actual build changes can be done
  // if ( window.location.href.indexOf('11cadogangardens') > -1 ) {
  //   $('.header__logo a').html('<img src="https://11cadogangardens.com/media/10331/cg-logo-landscape.png">');
  // }

	if ( $('.slider').length === 0 ) {
		$('body').addClass('no-slider');
	}



    setHeaderTransparency();
    $(window).scroll(setHeaderTransparency);

    setTimeout(function () {
      $('.header').addClass('visible') // only show once it definitely is the right style
    }, 300);
}

function setHeaderTransparency() {
  $header = $('.header');

  if ( $('.slider').length === 0 ) {
    $header.addClass('compacted');
    return;
  }

  if (window.pageYOffset >= 200) {
    $header.addClass('compacted');
  } else {
    $header.removeClass('compacted');
  }

    /*var sticky = $('.sticky'),
    scrollOffset = $(window).scrollTop();

    if ( $('body.no-slider').length > 0 ) {
        $('.header').addClass('sticky fixed');
    } else if ( ($('body.home').length > 0 ) && ( $(window).outerWidth() < 640 ) ) {
        sticky.addClass('fixed');
    } else {

        if (scrollOffset >= 200) {
            sticky.addClass('fixed');
            $('.header__logo .logo').css('display', 'none');
            $('.header__logo .logo-scroll').css('display', 'block');
        } else {
            sticky.removeClass('fixed');
            $('.header__logo .logo').css('display', 'block');
            $('.header__logo .logo-scroll').css('display', 'none');
        }
    }*/
}

function initGallery() {
    $('.gallery__item a').each(function () {
		if ( (typeof $(this).data('title') !== 'undefined') && ($(this).data('title') !== '') ) {
			$(this).append('<p>' + $(this).data('title') + '</p>');
            
		}
    });

    function refreshMasonry() {

            setTimeout(function()
        {
            $('.gallery__grid').masonry({
                columnWidth: '.gallery__item-sizer',
                itemSelector: '.gallery__item',
                percentPosition: true,
                horizontalOrder: true,
                fitWidth: false
            });
            console.log('triggered')
        }, 1000);
	};
    refreshMasonry()


 

	// reset masonry layout once all images have loaded
	Foundation.onImagesLoaded($('.gallery__grid .gallery__item img'), function () {
		// setTimeout(refreshMasonry, 1000);
        refreshMasonry()
	});




	$('.gallery').on('filter-changed', function () {
		// setTimeout(refreshMasonry, 500);
        refreshMasonry()
	});


    $('.gallery__item a').on('click', function (e) {
        e.preventDefault();

        var $clickedThumbnail = $(this);

        var imagelink = $(this).attr('href');
        var titletext = $(this).data('title');
        var descriptiontext = $(this).data('description');

        var modal = '<div id="gallery-modal" class="gallery-modal full reveal loading" data-reveal>';
        modal += '<div class="gallery-modal__image row"><div><button class="gallery-modal__close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><img src="' + imagelink + '"/> <button class="gallery-modal__prev">Previous</button><button class="gallery-modal__next">Next</button></div>';
        modal += '<div class="grid-x"><div class="pop-up-content cell large-6"><h2>' + titletext + '</h2></div>';
        modal += '<div class="description cell large-6"><p>' + descriptiontext + '</p></div></div>';
        modal += '</div></div>';


        var $modal = $(modal);

        $('body').append($modal);

        $('#gallery-modal').foundation();

        $('#gallery-modal').foundation('open');

        $('.gallery-modal__close').on('click', function () {
            $modal.foundation('close');
            $modal.remove();
        });







        $('.gallery-modal__next').on('click', function () {
            $modal.foundation('close');
            $modal.remove();
            if ( $clickedThumbnail.parent().next(':visible').length > 0 ) { // is the next thumb visible?
                $clickedThumbnail.parent().next(':visible').find('a').trigger('click');
            } else if ( $clickedThumbnail.parent().nextUntil(':visible').length > 0 ) { // no, so find the next visible one
                $clickedThumbnail.parent().nextAll(':visible').first().find('a').trigger('click');
                //$modal.foundation('close');
                //$modal.remove();
            }
        });

        $('.gallery-modal__prev').on('click', function () {
            $modal.foundation('close');
            $modal.remove();
            if ( $clickedThumbnail.parent().prev(':visible').length > 0 ) { // is the previous thumb visible?
                $clickedThumbnail.parent().prev(':visible').find('a').trigger('click');
            } else if ( $clickedThumbnail.parent().prevUntil(':visible').length > 0 ) { // no, so find the next visible one that comes before this
                $clickedThumbnail.parent().prevAll(':visible').last().find('a').trigger('click');
                //$modal.foundation('close');
                //$modal.remove();
            }
        });
        return false;
    });
}

function initPopups() {
    if ( $('#popup').length > 0 ) {
        var popupDelay = $('#popup').data('popup-delay') || 0;
        var popupKeepHiddenFor = $('#popup').data('popup-keep-hidden-for-n-days') || 0;

        if (!Cookies.get('hasSeenPopup')) {
            setTimeout(function () {
                $('#popup').foundation('open');

                if (popupKeepHiddenFor > 0) {
                	Cookies.set('hasSeenPopup', 'true', {expires: popupKeepHiddenFor});
                }
            }, popupDelay)
        }
    }
}

$('.popup__overlay').on('click', function () {
	$('#popup').foundation('close');
 });

 $('.popup__close').on('click', function () {
	$('#popup').foundation('close');
    console.log('clicked')
 }); 

function initPress() {
    $('.press__load button').on('click', function () {
        var loadURL = $(this).data('load');
        var $buttonContainer = $(this).parent();

        $.ajax({ type: 'GET',
            url: loadURL,
            success : function(ajaxContent) {
                $('.press__grid').append(ajaxContent);
                $buttonContainer.hide();
                initPress();
            }
        });
    });
}

var filterChecker;
function initGridPanels() {
    $('.grid-panels__nav a').on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $('a[name="' + $(this).attr('href').replace('#', '') + '"').offset().top - $('.header').outerHeight() - 100
        }, 1000);
    });

    $('.grid-panels__item-img').on('mouseenter', function() {
        if(window.innerWidth < 960) return;
        $(this).find('.grid-panels__item-hidden').css('opacity', '1');
    })

    $('.grid-panels__item-img').on('mouseleave', function() {
        if(window.innerWidth < 960) return;
        $(this).find('.grid-panels__item-hidden').css('opacity', '0');
    })

    filterChecker = setInterval(checkForGridFilters, 300);
}

function checkForGridFilters() {
  if ( $('.grid-panels__nav button').length > 2 ) {
    if ( $('body.theme-cadogan').length > 0 ) {
        $('.grid-panels__nav').css('display', 'block');
        clearInterval(filterChecker);
    } else {
        $('.grid-panels__nav').css('display', 'inline-block');
        clearInterval(filterChecker);
    }
  }
}

function initSplitIntroOverlay() {
    var overlay = $('.split-intro__image-overlay');
    if ( $('.split-intro__image-content').children().length < 1 ) {
        overlay.css('display', 'none');
    }
}

function initNewsletterOffCanvas() {
  
  $('a[href="#newsletter"]').on('click', function () {
    $('#newsletterOffCanvas').foundation('open');
  });

    $('#newsletterOffCanvas form').on('submit', function() {
        $('.accordionContent').show();
    });
    $('#newsletterOffCanvas form').validate({
        rules: {
            'interested[]': {
                required: true,
                minlength: 1
            }
        },
        messages: {
            'interested[]': 'Please select at least one.'
        },
        errorPlacement: function(label, element) {
            if (element.is('input[type="checkbox"]')) {
                label.insertBefore(element.parents('.accordionContent'));
            } else {
                label.insertAfter(element);
            }
        }
    });

    $('#newsletterOffCanvas select')
        .not('.not-selectric')
        .selectric();

    $('#accordion1').accordiom({
        showFirstItem: true
    });



    $('#1328993').on('change', function() {
        if ($(this).prop('checked')) {
            $('#general-information-textbox').slideDown();
        } else {
            $('#general-information-textbox').slideUp();
        }
    });

    $('.how-did-you-hear-about-us').on('change', function() {
        if ($(this).val() == '1329032') {
            $('#how-did-you-hear-about-us-textbox').slideDown();
        } else {
            $('#how-did-you-hear-about-us-textbox').slideUp();
        }
    });

}

function showNewsletterForm() {
    setTimeout(function() {
        $('.newsletterOffCanvas').css('opacity', '1');
    },2000);
}

function initCookerySchool() {
  $('.cookery-search__form-row select').customSelect();
  
  setTimeout(function () {
    if ( $('.cookery-courses__filter-list button').length < 3 ) {
      $('.cookery-courses__filter').hide();
    } else {
      $('.cookery-courses__filter').css('display', 'inline-block');
    }
  }, 300);
}


function initTreatments() {
  $('.treatments__accordion').accordiom({
    showFirstItem: false
  });
}

function initLazyLoading() {
    setTimeout(function () {
      $(window).trigger('resize'); // h4x0r
    }, 1000);
  
  
    var lazyImages = Array.from($("[data-style], .lazy > source, .lazy-img"));

    var options = {
        root: null,
        rootMargin: "150px 0px",
        threshold: 0,
      };

    var lazyImageObserver = new IntersectionObserver(callbackObserver, options);

    function presetImgSizeStyles(img) {
        if (
            img.nodeName === "SOURCE" ||
            img.nodeName === "DIV" ||
            img.classList.contains("bg-img") ||
            typeof img.dataset.presets !== "undefined"
          ) {
            return;
          }
      
          img.style.width = "100%";
          img.style.height = "auto";
          img.style.display = "block";
          img.decoding = "async";
      }   
    
    function setImageViewportWidth(img) {
        var windowWidth = window.innerWidth;
        var imgWidth = img.clientWidth;
        var imgViewportWidth = Math.floor((imgWidth / windowWidth) * 100) + "vw";
        img.sizes = imgViewportWidth;
      }

    function handleImgSrcset(img) {
        setImageViewportWidth(img);  
        img.srcset = img.dataset.srcset;
        img.src = img.dataset.src;
        delete img.dataset.srcset;
        delete img.dataset.src;
        lazyImageObserver.unobserve(img);
        
      }

    function handleImgTag(img) {
        img.src = img.dataset.src;
        delete img.dataset.src;
        lazyImageObserver.unobserve(img);
    }   

    function handleBackgroundImg(img) {
        img.style = img.dataset.style; 
        delete img.dataset;
        lazyImageObserver.unobserve(img);
      }

    function handlePictureElement(img) {
        if (img.nextElementSibling.nodeName === "IMG") {
          img.nextElementSibling.src = img.nextElementSibling.dataset.src;
          delete img.nextElementSibling.dataset.src;
        }
    
        img.srcset = img.dataset.srcset;
        delete img.dataset.srcset;
    
        img.parentElement.classList.remove("lazy");
        lazyImageObserver.unobserve(img);
    }  



    function callbackObserver(entries) {
        entries.forEach(function(entry) {
          var img = entry.target;
          
          if (!entry.isIntersecting) return;
    
          if (img.parentElement.nodeName === "PICTURE") {
            handlePictureElement(img);
            return
          }

          if (img.nodeName === "IMG" && typeof img.dataset.srcset !== 'undefined') {
            handleImgSrcset(img); 
            return
          }

          if (img.nodeName === "IMG" && typeof img.dataset.srcset === "undefined") {
            handleImgTag(img); 
            return
          }

          if (img.nodeName === "DIV") {
            handleBackgroundImg(img);
            return
          }

          lazyImageObserver.unobserve(img);
        });
      }

    
    lazyImages.forEach(function (img) {
        presetImgSizeStyles(img)
      })  

    lazyImages.forEach(function (img) {
        lazyImageObserver.observe(img);
      });
}


function initStarReviews() {
  var content = $('.review-content')
  var limitOfWords = 15;
  var path = {
      full: "full",
      half: "half",
      empty: "empty",
  }
  var colours = {
      white: "#fff",
      default: "#cdc5a7"
  }

  var container = $('.stars')
  var revealedContainer = $('.stars-revealed') 
  var callReveal = $('.to-reveal')
  var revealNext = $('.review-revealed__inner__next')
  var revealPrev = $('.review-revealed__inner__prev')
 

  function getPath(name, colour) {
      if (name === "empty") {
          return '<path id="Icon_metro-star-empty" data-name="Icon metro-star-empty" d="M23.6,10.322,16.335,9.267,13.085,2.683,9.836,9.267,2.571,10.322l5.257,5.125L6.587,22.683l6.5-3.416,6.5,3.416-1.241-7.236L23.6,10.322Zm-10.515,7.32L8.5,20.055l.876-5.11L5.66,11.326l5.131-.746,2.295-4.649L15.38,10.58l5.131.746L16.8,14.945l.876,5.11-4.589-2.413Z" transform="translate(-2.571 -2.683)" fill="' + colour + '"/>'
      } 

      if (name === "half") {
          return '<path id="Icon_metro-star-half" data-name="Icon metro-star-half" d="M23.6,10.322,16.335,9.267,13.085,2.683,9.836,9.267,2.571,10.322l5.257,5.125L6.587,22.683l6.5-3.416,6.5,3.416-1.241-7.236L23.6,10.322Zm-10.515,7.32-.019.01.019-11.721L15.38,10.58l5.131.746L16.8,14.945l.876,5.11-4.589-2.413Z" transform="translate(-2.571 -2.683)" fill="' + colour + '"/>'
      }

      if (name === "full" ) {
          return '<path id="Icon_metro-star-full" data-name="Icon metro-star-full" d="M23.6,10.322,16.335,9.267,13.085,2.683,9.836,9.267,2.571,10.322l5.257,5.125L6.587,22.683l6.5-3.416,6.5,3.416-1.241-7.236L23.6,10.322Z" transform="translate(-2.571 -2.683)" fill="' + colour + '"/>'
      }

      return
  }

  function createStar(path) {
      var star = '<svg xmlns="http://www.w3.org/2000/svg" width="21.029" height="20" viewBox="0 0 21.029 20">' + path+ '</svg>'
      return star
  }

  function getStars(number, path) {
    var stars = []
    for (var i = 0; i < number; i++) {
        stars.push(createStar(path))
    }
    return stars
  }


  function setStars(value, colour) {
    var stars = getStars(5, getPath(path.full, colour))

    switch (value) {

        case 4.5:
        stars.splice(4,2,createStar(getPath(path.half, colour)))
        break;

        case 4:
        stars.splice(4,2,createStar(getPath(path.empty, colour)))
        break;

        case 3.5:
        stars.splice(3,2,createStar(getPath(path.half, colour)))
        stars.push(createStar(getPath(path.empty, colour)))
        break;

        case 3: 
        stars.splice(3,2,createStar(getPath(path.empty, colour)))
        stars.push(createStar(getPath(path.empty, colour)))
        break;

        case 2.5: 
        stars.splice(2,3,createStar(getPath(path.half, colour)))
        stars.push(getStars(2, getPath(path.empty, colour)))
        break;
        
    }
    return stars
  }

  function setText(arrayOfText) {
    var shortenedText;
    var isLong = false;

    if (arrayOfText.length > limitOfWords) {
        shortenedText = arrayOfText.slice(0, limitOfWords).join(" ") + "..."
        isLong = true;   
    } else {
        shortenedText = arrayOfText.join(" ")
    }
    return prop = {
        shortenedText: shortenedText,
        isLong: isLong
    }   
  }

  function setReadMore(content) {
    var rawArr= content.text().split(" ")
    var editedArr = setText(rawArr)
    return editedArr
  }

  function setButton(state, element) {
      if (!state) return
      element.append(' <button>Read More</button>') 
  }

  function handleFirstandLast (contents, prev, next) {
      contents.each(function () {
          if ($(this).eq(0)) {
            prev.eq(0).css('opacity', 0.2) 
          }
          if ($(this).eq(contents.length - 1)) {
            next.eq(contents.length - 1).css('opacity', 0.2) 
          }
          return
      })
  }

  handleFirstandLast(revealedContainer, revealPrev, revealNext)

  container.each(function () {
    var point = $(this).data('points')
    $(this).append(setStars(point, colours.default))
  })

  revealedContainer.each(function () {
    var point = $(this).data('points')
    $(this).append(setStars(point, colours.white))
  })
  
  content.each(function () {
    var shortContent = setReadMore($(this))
    $(this).text(shortContent.shortenedText) 
    setButton(shortContent.isLong, $(this))
  })


    callReveal.each(function (index) {
        $(this).on('click', function () {
            $('#item-' + (index+1) + '-reveal').foundation('open');
        })   
    })

    revealNext.each(function (index) {
        $(this).on('click', function () {
            if (index > revealNext.length - 2) return
            $('#item-' + (index+2) + '-reveal').foundation('open');
        })
    })

    revealPrev.each(function (index) {
        $(this).on('click', function () {
            if (index < 1) return
            $('#item-' + (index) + '-reveal').foundation('open');
        })
    })

}

function initNewsletterPopUp () {
    var form =  $('#newsletterOffCanvas');
    var isPopUp = form.data('pop-up')
    var delay = form.data('delay') * 1000 || 0;
    var popupKeepHiddenFor = form.data('popup-keep-hidden-for-n-days') || 0;

    if (Cookies.get('seenNewsLetter') || !form.length > 0 || !isPopUp) return;

    setTimeout(function() {
        form.foundation('open');
        }, delay)
    Cookies.set('seenNewsLetter', 'true', {expires: popupKeepHiddenFor});
}

function initLazyCurator() {
	var elements = $('[data-lazy-curator]')
	var options = {
		root: null,
		rootMargin: "600px 0px",
		threshold: 0,
	};

	function callbackObserver(entries) {
		entries.forEach(function(entry) {
			var element = entry.target;
			var $element = $(element);
			if (!entry.isIntersecting) return;
			
			// Dynamically generate the script tag
			var scriptTag = document.createElement('script');
			scriptTag.onload = function () {
				// Wait for it to load then fire the full Curator embed snippet
				$element.html( $element.data('lazy-curator') );
			};
			
			// Extract the JS URL
			// Look for a string starting with // and ending .js
			var curatorCode = $element.data('lazy-curator');
			scriptTag.src = curatorCode.match(/(\/)(\/)(.*)(\.js)/g)[0];
			document.head.appendChild(scriptTag);
			
			observer.unobserve(element)
		});
	}

	function setObserver(elements) {
		elements.each(function() {
			observer.observe($(this)[0])
		})
	}
	var observer = new IntersectionObserver(callbackObserver, options);
	setObserver(elements)
}

